















































































































import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import { Toast } from "vant";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class PayPay extends Vue {
  public form_list: MyForm.formList[] = [];
  public showPicker = false; // 显示收款银行卡选择框
  public pay_list = {}; // 收款银行卡列表
  public pay_index = 0; // 当前选择的收款银行卡
  public amount = ""; // 付款的金额
  public order = {}; // 订单信息
  public user_id = "";
  public order_id = ""; // 订单id

  public showSuccess = false; // 是否显示支付成功页面

  // 收款银行卡选择
  cardConfirm(item, index) {
    this.pay_index = index;
    this.showPicker = false;
  }
  // form组件的回调（值改变时获取数据）
  formChange(form_list: MyForm.formList[]) {
    this.form_list = form_list;
  }
  // 获取form表单的数据
  getFormData(): Dictionary<string> {
    let form: Dictionary<string> = this.$api.getFormData([this.form_list]);
    form.pay_time = this.$api.date("Y-m-d H:i", form.pay_time);
    form.pay_id = this.pay_list[this.pay_index].pay_id;
    form.amount = this.amount;
    return form;
  }
  // 提交表单
  submit() {
    let form: any = this.$refs.form;
    if (form.ValidateAll()) {
      this.submitRequest(this.getFormData());
    }
  }
  submitRequest(data: Dictionary<string>) {
    let _this = this;
    let url = "common/pay-data-log/add-pay-data-log";
    data.user_id = this.user_id;
    // data.order_id = this.order_id;
    data.is_region = '1';
    this.$api.request({
      url,
      data,
      success(res) {
        _this.showSuccess = true;
        Toast("已提交审核，等待审核中...");
      }
    });
  }

  获取pay_id列表
  getPayId() {
    let _this = this;

    this.$api.request({
      url: "common/pay-ment/get-pay-list",
      success(res) {
        let data = {};
        _this.pay_list = res.data;
        _this.pay_index = 0;
      }
    });
  }
  // 获取订单详情（需要支付的金额）
  // getOrderDetail() {
  //   let _this = this;
  //   this.$api.request({
  //     url: "order/order-info/order-details",
  //     data: {
  //       order_id: this.order_id,
  //       user_id: this.user_id
  //     },
  //     success(res) {
  //       _this.order = res.data;
  //       console.log(res);
  //     }
  //   });
  // }

  init() {
    this.showPicker = false;
    this.pay_list = {};
    this.pay_index = 0;
    this.amount = "";
    this.order = {};
    this.user_id = String(this.$route.query.user_id || "");
    this.order_id = String(this.$route.query.order_id || "");
    this.showSuccess = false;
    this.form_list = [
      {
        name: "voucher_img",
        value: "",
        label: "付款凭证",
        type: "image",
        required: true
      },
      {
        name: "remit_name",
        value: "",
        label: "打款人",
        type: "input",
        placeholder: "请输入打款人姓名",
        required: true
      },
      {
        name: "pay_time",
        value: String(new Date().getTime()),
        label: "支付时间",
        type: "datePicker",
        date_type: "datetime",
        max_date: new Date(),
        min_date: new Date(1000, 0, 1),
        required: true
      }
    ];
    this.$api.refreshForm([this.$refs.form]);
    this.getPayId();
    // this.getOrderDetail();
  }
}
